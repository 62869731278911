import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaBoxOpen, FaTag, FaClipboardList, FaWarehouse, FaUsers } from 'react-icons/fa'; // Added FaUsers for the Users Card
import { Link } from 'react-router-dom';
import { getBrand } from '../../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { getCategory } from '../../../redux/Slices/SuperAdmin/categorySlice';
import { getGodown } from '../../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { getUsers } from '../../../redux/Slices/SuperAdmin/userSlice';
import Topbar from '../../../components/Layout/Topbar';
import { getProduct } from '../../../redux/Slices/SuperAdmin/productSlice';

const Dashboard = () => {
    const { AllProduct = [] } = useSelector(state => state.product);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { AllUsers } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(getProduct());
        dispatch(getBrand());
        dispatch(getCategory());
        dispatch(getGodown());
        dispatch(getUsers());
    }, [dispatch]);

    // const totalProducts = AllProduct.length;
    // const totalInventories = AllProduct.reduce((acc, product) => acc + product.inventories.length, 0);
    // const totalBatches = AllProduct.reduce((acc, product) => {
    //     return acc + product.inventories.reduce((acc2, inventory) => {
    //         return acc2 + (inventory.holdqty ? inventory.holdqty.length : 0);
    //     }, 0);
    // }, 0);
    // const availableProducts = AllProduct.filter(product => product.inventories && product.inventories.length > 0);

    // const totalAvailableProducts = availableProducts.length;
    // const stoppedProduct = AllProduct.filter(product => product.dis_continue === '0');
    // const stopCount = stoppedProduct.length;

    // Count active and inactive users

    const totalProducts = localStorage.getItem('totalProducts');
    const totalInventories = localStorage.getItem('totalInventories');
    const totalBatches = localStorage.getItem('totalBatches');
    const totalAvailableProducts = localStorage.getItem('totalAvailableProducts');
    const stopCount = localStorage.getItem('stopCount');


    const activeUsers = AllUsers.filter(user => user.is_active == "1").length;
    const appUser = AllUsers.filter(user => user.rolename === "App User").length;
    const admins = AllUsers.filter(user => user.rolename === "Godown Admin").length;
    const inactiveUsers = AllUsers.length - activeUsers;

    return (
        <>
            <Topbar />
            <div className="p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {/* Total Products Card */}
                    <div className="bg-gradient-to-r from-blue-400 to-blue-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaBoxOpen className="text-4xl text-white mb-2" />
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">Total Products</h2>
                                    <p className="text-2xl text-white">{totalProducts}</p>
                                </span>
                            </div>
                            <span className='flex justify-around gap-20 items-end'>
                                <span className='flex flex-col '>
                                    <p className="text-sm text-white">Available : {totalAvailableProducts} </p>
                                    <p className="text-sm text-white">Discontinued : {stopCount} </p>

                                </span>
                                <Link to="/products" className="text-white underline mt-2 text-right hover:text-blue-200">Know More</Link>
                            </span>
                        </div>

                    </div>

                    {/* Available Batches Card */}
                    <div className="bg-gradient-to-r from-green-400 to-green-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaClipboardList className="text-3xl text-white mb-2" /> {/* Reduced size */}
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">Available Batches</h2>
                                    <p className="text-xl text-white">{totalInventories}</p> {/* Reduced size */}
                                </span>
                            </div>
                            <Link to="/products" className="text-white underline mt-2 text-right hover:text-green-200">Know More</Link>
                        </div>
                    </div>

                    {/* Products On Hold Card */}
                    <div className="bg-gradient-to-r from-yellow-400 to-yellow-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaWarehouse className="text-3xl text-white mb-2" /> {/* Reduced size */}
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">Godowns</h2>
                                    <p className="text-xl text-white">{godownData.length}</p> {/* Reduced size */}
                                </span>
                            </div>
                            <Link to="/super-godown" className="text-white underline mt-2 text-right hover:text-yellow-200">Know More</Link>
                        </div>
                    </div>

                    {/* Total Brands Card */}
                    <div className="bg-gradient-to-r from-purple-400 to-purple-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaTag className="text-3xl text-white mb-2" /> {/* Reduced size */}
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">Total Brands</h2>
                                    <p className="text-xl text-white">{brandData.length}</p> {/* Reduced size */}
                                </span>
                            </div>
                            <Link to="/super-brand" className="text-white underline mt-2 text-right hover:text-purple-200">Know More</Link>
                        </div>
                    </div>

                    {/* Number of Categories Card */}
                    <div className="bg-gradient-to-r from-orange-400 to-orange-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaTag className="text-3xl text-white mb-2" /> {/* Reduced size */}
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">No. of Categories</h2>
                                    <p className="text-xl text-white">{data.length}</p> {/* Reduced size */}
                                </span>
                            </div>
                            <Link to="/category" className="text-white underline mt-2 text-right hover:text-orange-200">Know More</Link>
                        </div>
                    </div>

                    {/* Users Card */}
                    <div className="bg-gradient-to-r from-red-400 to-red-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                        <div className="flex flex-col justify-between h-32">
                            <div className='flex justify-around gap-20'>
                                <FaUsers className="text-3xl text-white mb-2" />
                                <span className='flex flex-col items-end'>
                                    <h2 className="font-bold text-lg text-white uppercase">Users</h2>
                                    <p className="text-xl text-white">{AllUsers.length} </p>
                                </span>
                            </div>
                            <span className='flex justify-around gap-20 items-end'>
                                <span className='flex flex-col '>
                                    <p className="text-sm text-white">Active : {activeUsers} </p>
                                    <p className="text-sm text-white">Inactive : {inactiveUsers} </p>
                                    <p className="text-sm text-white">App User : {appUser} </p>
                                    <p className="text-sm text-white">Admins : {admins} </p>
                                </span>
                                <Link to="/users-list" className="text-white underline mt-2 text-right hover:text-red-200">Know More</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
