import React, { useState } from 'react';
import { AiOutlinePicture } from 'react-icons/ai';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ImageGallery = ({ imageUrl, imageUrl2, imageUrl3, imageUrl4 }) => {
    const [showImage, setShowImage] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Create an array of images from the props
    const images = [imageUrl, imageUrl2, imageUrl3, imageUrl4].filter(url => url && url !== 'https://inventory.risapp.co.in/public/storage');

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <>
            <div className="cursor-pointer" onClick={() => { setShowImage(true); setCurrentImageIndex(0); }}>
                <AiOutlinePicture size={24} />
            </div>

            {showImage && (
                // Modal Background
                <div
                    className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                    onClick={() => setShowImage(false)}
                >
                    {/* Modal Content */}
                    <div
                        className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                        style={{ width: '80%', maxWidth: '500px' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Image */}
                        <div className="relative flex justify-center" style={{ width: '100%', height: '400px' }}>
                            <img
                                src={images[currentImageIndex]}
                                alt={`Image ${currentImageIndex + 1}`}
                                className="w-full h-full object-cover rounded-lg"
                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }}
                            />

                            {/* Navigation Buttons */}
                            <IconButton
                                onClick={handlePrev}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                disabled={images.length <= 1}
                            >
                                <ChevronLeftIcon style={{ color: 'white' }} />
                            </IconButton>
                            <IconButton
                                onClick={handleNext}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                disabled={images.length <= 1}
                            >
                                <ChevronRightIcon style={{ color: 'white' }} />
                            </IconButton>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-4 flex justify-center space-x-4">
                            <button
                                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                onClick={() => setShowImage(false)}
                            >
                                Close
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                onClick={() => window.open(images[currentImageIndex], '_blank')}
                            >
                                Open in New Tab
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageGallery;