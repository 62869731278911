import { FaBars, FaUserCircle, FaPowerOff } from 'react-icons/fa'; // Import React Icons
import { logout } from '../../redux/Slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { Tooltip } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useState } from 'react';

const Topbar = ({ activeTab }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const { roleId } = useSelector((state) => state.auth);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreen = () => {
        const doc = window.document;
        const docEl = doc.documentElement;

        if (
            !doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement
        ) {
            if (docEl.requestFullscreen) {
                docEl.requestFullscreen();
            } else if (docEl.mozRequestFullScreen) {
                docEl.mozRequestFullScreen();
            } else if (docEl.webkitRequestFullscreen) {
                docEl.webkitRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.mozCancelFullScreen) {
                doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };



    const onLogout = () => {
        dispatch(logout());
        navigate('/');
    }

    return (
        <nav className="bg-gray-900 shadow-lg flex fixed top-0 left-0 right-0 z-30 p-4">
            <div className="container mx-auto flex justify-between items-center mr-14">

                {currentPath !== '/super-admin' && (
                    <div className="flex-1 text-center">
                        <span className="bg-gradient-to-r from-orange-500 to-yellow-500 ml-56 text-white px-3 py-1 rounded-full text-base font-bold uppercase shadow-md hover:scale-105 transition duration-300">{activeTab}</span>
                    </div>
                )}
                <div className="flex items-center space-x-4 ml-auto">
                    <FaUserCircle className="h-8 w-8 text-white" />
                    <span className="text-white font-medium">{roleId === '1' ? 'Super Admin' : 'Sub Admin'}</span>
                    <button
                        onClick={onLogout}
                        className="text-white hover:text-gray-400"
                    >
                        <FaPowerOff className="h-6 text-red-500 w-6 hover:scale-110 hover:text-red-400" />
                    </button>

                </div>
            </div>
            <span className='absolute right-3 top-3 bg-purple-500 p-2 rounded-lg hover:scale-110' onClick={handleFullscreen}
            >
                {isFullscreen ? (
                    <Tooltip title="Exit Fullscreen" arrow>
                        <ExitToAppIcon
                            large
                            className="items-center text-white justify-center "
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Fullscreen" arrow>
                        <AspectRatioIcon
                            large
                            className="items-center text-white justify-center"
                        />
                    </Tooltip>
                )}
            </span>
        </nav>
    );
};

export default Topbar;
