import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaMobileAlt, FaFileAlt, FaAngleUp, FaAngleDown, FaBoxOpen, FaRuler, FaBriefcase, FaBox, FaUsers, FaHome, FaHeart, FaArchive, FaShoppingCart, FaTag, FaUserCog, FaBan, FaReply } from 'react-icons/fa';
import { useState } from 'react';
import { MdMeasure } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Description, Web } from '@mui/icons-material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ImageIcon from '@mui/icons-material/Image';
import CategoryIcon from '@mui/icons-material/Category';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const location = useLocation();
    const name = localStorage.getItem('name');
    const [isOpenUsers, setIsOpenUsers] = useState(false);
    const [inventory, setInventory] = useState(false);
    const [report, setReport] = useState(false);
    const [product, setProduct] = useState(false);
    const { roleId } = useSelector((state) => state.auth);
    const [web, setWeb] = useState(false);

    // Check if the link is active
    const isActive = (path) => location.pathname === path ? 'bg-purple-700 text-white' : '';

    return (
        <div className={`z-50 bg-gray-900 fixed text-white h-screen ${isOpen ? 'w-64' : 'w-16'} transition-all duration-300`}>
            <div className="flex flex-col h-full">
                {/* Header Section */}
                <div className="flex items-center justify-between p-4" onClick={toggleSidebar}>
                    {isOpen && (
                        <div className={`flex items-center space-x-2 w-full`}>
                            <h1 className={`text-xl font-bold mt-1 cursor-pointer text-purple-200`}>{name}</h1>
                        </div>
                    )}
                    {/* Toggle Button */}
                    <button
                        className={`text-white ${isOpen ? 'right-3' : 'left-3'} hover:text-gray-200 transition-colors p-2 rounded-full focus:outline-none absolute top-4`}
                        onClick={() => { toggleSidebar(); setInventory(!inventory) }}>
                        <FaBars className={`h-5 w-5 text-white transition duration-300  transform hover:scale-110 hover:rotate-45`} />
                    </button>
                </div>

                {/* Sidebar Links */}
                <ul className="flex-1 mt-3 overflow-y-auto">
                    <Link
                        to="/super-admin"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/super-admin')}`}>
                        <FaHome className={`h-7 w-7 text-blue-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Dashboard</span>}
                    </Link>

                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/products')} ${isActive('/hold')} hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setProduct(!product)}
                    >
                        <FaBox className={`h-7 w-7 text-red-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Products</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 w-7 text-white transition duration-300 transform hover:scale-110 ml-2 ${product ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>
                    {isOpen && product && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/products"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/products')}`}
                                >
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">All Products</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/hold"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/hold')}`}
                                >
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">On Hold</span>
                                </Link>
                            </li>
                        </ul>
                    )}

                    {roleId === '1' && (
                        <Link
                            className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/stock-in')} ${isActive('/damaged-out')} ${isActive('/stock-out')} ${isActive('/return-in')}  hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                            onClick={() => setInventory(!inventory)}
                        >
                            <FaShoppingCart className="h-7 w-7 text-green-500" />
                            {isOpen && <span className="font-medium text-gray-200">Stock</span>}
                            {isOpen && (
                                <FaAngleDown
                                    className={`h-5 w-7 text-white transition duration-300 transform hover:scale-110 ml-2 ${inventory ? 'rotate-180' : ''}`}
                                />
                            )}
                        </Link>
                    )}

                    {isOpen && inventory && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/stock-in"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/stock-in')}`}
                                >
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stock In</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/stock-out"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/stock-out')}`}
                                >
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stock Out</span>
                                </Link>
                                <Link
                                    to="/returns"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/return-in')}`}
                                >
                                    <FaReply className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Return</span>
                                </Link>
                                <Link
                                    to="/damages"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/damaged-out')}`}
                                >
                                    <FaBan className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Damaged</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                    <Link
                        to="/category"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/category')}`}>
                        <FaArchive className={`h-7 w-7 text-white`} />
                        {isOpen && <span className="font-medium text-gray-200">Category</span>}
                    </Link>

                    <Link
                        to="/Measures"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/Measures')}`}>
                        <FaRuler className={`h-7 w-7 text-red-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Measurement</span>}
                    </Link>

                    <Link
                        to="/super-brand"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/super-brand')}`}>
                        <FaBriefcase className={`h-7 w-7 text-orange-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Brand</span>}
                    </Link>

                    <Link
                        to="/super-godown"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/super-godown')}`}>
                        <FaTag className={`h-7 w-7 text-green-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Godowns</span>}
                    </Link>


                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/in')} ${isActive('/return-rep')} ${isActive('/out')} ${isActive('/damage-rep')} hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setReport(!report)}
                    >
                        <FaFileAlt className="h-7 w-7 text-green-500" />
                        {isOpen && <span className="font-medium text-gray-200">Reports</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 w-7 right-2 text-white transition duration-300 transform hover:scale-110 ml-2 ${report ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>

                    {isOpen && report && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/in"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/in')}`}>
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stocks In</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/out"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/out')}`}>
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stocks Out</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/return-rep"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/return-rep')}`}>
                                    <FaReply className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Return</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/damage-rep"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/damage-rep')}`}>
                                    <FaBan className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Damage</span>
                                </Link>
                            </li>
                        </ul>
                    )}


                    <Link
                        to="/users-list"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/users-list')}`}>
                        <FaUsers className={`h-7 w-7 text-blue-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Users</span>}
                    </Link>

                    <Link
                        to="/devices"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/devices')}`}>
                        <FaMobileAlt className={`h-7 w-7 text-blue-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Devices</span>}
                    </Link>
                    <Link
                        to="/logs"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/logs')}`}>
                        <Description className={`h-7 w-7 text-blue-500`} />
                        {isOpen && <span className="font-medium text-gray-200">LOGS</span>}
                    </Link>

                    {/* web part */}

                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/web/category')} ${isActive('/web/orders')} ${isActive('/web/enquiry')} ${isActive('/web/banner')} hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setWeb(!web)}
                    >
                        <Web className="h-7 w-7 text-green-500" />
                        {isOpen && <span className="font-medium text-gray-200">Web Controls</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 w-7 right-2 text-white transition duration-300 transform hover:scale-110 ml-2 ${web ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>

                    {isOpen && web && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/web/category"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/web/category')}`}>
                                    <CategoryIcon className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Category</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/web/banner"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/banner')}`}>
                                    <ImageIcon className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Uploads</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/web/orders"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/orders')}`}>
                                    <ShoppingBasketIcon className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Order Request</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/web/enquiry"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/enquiry')}`}>
                                    <QuestionAnswerIcon className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Enquiry</span>
                                </Link>
                            </li>
                        </ul>
                    )}

                </ul>

                {isOpen && (
                    <span className='flex flex-col'>
                        <span className='text-xs -mb-2' style={{ display: 'inline-flex', alignItems: 'center' }}>
                            Made with <FaHeart className="h-3 w-3 m-2 text-red-500" /> by Ruminate IT Solutions
                        </span>
                        <span className='text-xs mb-0' style={{ display: 'inline-flex', alignItems: 'center' }}>
                            version : 1.0.0.2
                        </span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
