import { addProduct } from "../../redux/Slices/SuperAdmin/productSlice"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrand } from '../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { getGodown } from '../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { getCategory } from '../../redux/Slices/SuperAdmin/categorySlice';
import { getUom } from '../../redux/Slices/Measures/uomSlice';
import { getSize } from '../../redux/Slices/Measures/sizeSlice';
import { useNavigate } from "react-router-dom";
import { addAdminProduct } from "../../redux/Slices/Admin/adminProductSlice";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";

const AddProduct = () => {
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [size, setSize] = useState('');
    const [qty, setQty] = useState('');
    const [batch, setBatch] = useState('');
    const [uom, setUom] = useState('');
    const [productInDate, setProductInDate] = useState(new Date().toISOString().split('T')[0]);
    const [color, setColor] = useState('');
    const [brand, setBrand] = useState('');
    const [category_id, setCategory_id] = useState('');
    const [mrp, setMrp] = useState('');
    const [thickness, setThickness] = useState('');
    const [godowns, setGodown] = useState('');
    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);
    const [sub_category, setSubCategories] = useState('');
    const [productVaidation, setProductValidation] = useState();
    const [finish, setFinish] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleId } = useSelector((state) => state.auth);
    const { AdminProducts = [], adminAddStatus } = useSelector(state => state.adminProduct);
    const { addStatus, error } = useSelector((state) => state.product);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { uomData = [] } = useSelector(state => state.superAdminUom);
    const { sizeData = [] } = useSelector(state => state.superAdminSize);
    const { finishData = [] } = useSelector(state => state.finish);
    const { AllProduct = [] } = useSelector(state => state.product);

    useEffect(() => {
        dispatch(getFinish());
        dispatch(getBrand());
        dispatch(getGodown());
        dispatch(getCategory());
        dispatch(getUom());
        dispatch(getSize());

    }, [dispatch]);

    const existingSkus = AllProduct.map(product => product.sku);

    // console.log("skussss  " + existingSkus)

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log('clicked hit')
        const errors = {};

        if (existingSkus.includes(sku)) {
            toast.warning('SKU already exists! Please enter a unique SKU.');
            return;
        }

        setProductValidation(errors);


        if (roleId === '1') {
            const productData = {
                name,
                sku,
                size,
                uom,
                product_in_date: productInDate,
                color,
                brand,
                category_id,
                sub_category,
                thickness,
                finish,
                image,
                image2,
                image3,
                image4
            };
            // const products = [productData];
            dispatch(addProduct(productData));
        } else {
            // console.log('111111')
            const productData = {
                name,
                sku,
                size,
                uom,
                product_in_date: productInDate,
                color,
                brand,
                category_id,
                sub_category,
                thickness,
                finish,
                image,
                image2,
                image3,
                image4
            };
            const products = [productData];

            dispatch(addAdminProduct({ products }));
        }

    };




    const subcategoryOptions = data
        .filter((category) => category.id == category_id)
        .map((category) => category.subcategories.map((sub) => ({ id: sub.id, name: sub.name })));


    return (
        <>

            <ToastContainer theme="dark" position="bottom-center" />
            <div className="max-w-3xl h-[28rem] mx-auto bg-white p-3 rounded-lg shadow-md mt-3">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">
                        {/* <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="godowns"
                                >
                                    Godown
                                </label>

                                {productVaidation && productVaidation.godowns && <span style={{ color: 'red' }}>{productVaidation.godowns}</span>}
                            </span>
                            <Select
                                id="godowns"
                                // value={godowns ? { value: godowns, label: godowns } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setGodown(option.value);
                                    } else {
                                        setGodown(option);
                                    }
                                }}
                                options={godownData.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div> */}
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="sku"
                            >
                                SKU
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="sku"
                                type="text"
                                value={sku}
                                onChange={(event) => setSku(event.target.value)}
                                placeholder="Enter SKU"
                            />
                        </div>

                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="productName"
                                >
                                    Product Name
                                </label>
                                {productVaidation && productVaidation.name && <span style={{ color: 'red' }}>{productVaidation.name}</span>}
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="productName"
                                type="text"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                placeholder="Enter product name"
                            />
                        </div>

                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="brand"
                                >
                                    Brand
                                </label>
                            </span>
                            <Select
                                id="brand"
                                // value={brand ? { value: brand, label: brand } : null}

                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setBrand(option.value);
                                    } else {
                                        setBrand(option);
                                    }
                                }}
                                options={brandData.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">


                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="category"
                            >
                                Category
                            </label>

                            <Select
                                id="category"
                                // value={category_id? { value: category_id, label: category_id } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setCategory_id(option.value);
                                    } else {
                                        setCategory_id(option);
                                    }
                                }}
                                options={data.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="sub_category"
                            >
                                Sub Category
                            </label>

                            <Select
                                id="sub_category"
                                // value={sub_category? { value: sub_category, label: sub_category } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setSubCategories(option.value);
                                    } else {
                                        setSubCategories(option);
                                    }
                                }}
                                options={subcategoryOptions[0]?.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div>

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="size"
                            >
                                Size
                            </label>
                            <Select
                                id="size"
                                // value={size? { value: size, label: size} : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setSize(option.value);
                                    } else {
                                        setSize(option);
                                    }
                                }}
                                options={sizeData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>

                        {/* <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="batch"
                                >
                                    Batch No.
                                </label>
                                {productVaidation && productVaidation.batch && <span style={{ color: 'red' }}>{productVaidation.batch}</span>}
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="batch"
                                type="text"
                                value={batch}
                                onChange={(event) => setBatch(event.target.value)}
                                placeholder="Enter batch number"
                            />
                        </div> */}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">



                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="uom"
                            >
                                UOM
                            </label>
                            <Select
                                id="uom"
                                // value={uom? { value: uom, label: uom} : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setUom(option.value);
                                    } else {
                                        setUom(option);
                                    }
                                }}
                                options={uomData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="color"
                            >
                                Color
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="color"
                                type="text"
                                value={color}
                                placeholder="Eneter product color"
                                onChange={(event) => setColor(event.target.value)}
                            />
                        </div>
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="uom"
                            >
                                Finish
                            </label>
                            <Select
                                id="finish"
                                // value={finish? { value: finish, label: finish} : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setFinish(option.value);
                                    } else {
                                        setFinish(option);
                                    }
                                }}
                                options={finishData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">

                        {/* <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="qty"
                                >
                                    Quantity
                                </label>
                                {productVaidation && productVaidation.qty && <span style={{ color: 'red' }}>{productVaidation.qty}</span>}
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="qty"
                                type="number"
                                value={qty}
                                onChange={(event) => setQty(event.target.value)}
                                placeholder="Enter quantity"
                            />
                        </div> */}

                        {/* <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="mrp"
                            >
                                MRP
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="mrp"
                                type="number"
                                value={mrp}
                                onChange={(event) => setMrp(event.target.value)}
                                placeholder="Enter MRP"
                            />
                        </div> */}
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="thickness"
                            >
                                Thickness (in MM)
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="thickness"
                                type="text"
                                value={thickness}
                                onChange={(event) => setThickness(event.target.value)}
                                placeholder="Enter thickness"
                            />
                        </div>
                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="product_in_date"
                                >
                                    Date
                                </label>
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="productInDate"
                                type="date"
                                value={productInDate}
                                onChange={(event) => setProductInDate(event.target.value)}
                            />
                        </div>

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="image"
                            >
                                Image
                            </label>

                            <div className="flex gap-2">
                                {/* First Upload Button */}
                                <div
                                    className="flex items-center justify-center w-16 h-11 border border-gray-300 rounded-lg cursor-pointer hover:border-orange-500 hover:bg-orange-50 transition"
                                    onClick={() => document.getElementById('image').click()}
                                >
                                    {image ? (
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt="Uploaded"
                                            className="object-cover w-full h-full rounded-lg"
                                        />
                                    ) : (
                                        <span className="text-gray-400 text-xl font-bold">+</span>
                                    )}
                                </div>
                                <input
                                    id="image"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={(event) => {
                                        const file = event.target.files[0];
                                        setImage(file);
                                    }}
                                />
                                {/* {roleId === '1' && ( */}
                                <>
                                    {/* second */}
                                    < div
                                        className="flex items-center justify-center w-16 h-11 border border-gray-300 rounded-lg cursor-pointer hover:border-orange-500 hover:bg-orange-50 transition"
                                        onClick={() => document.getElementById('image2').click()}
                                    >
                                        {image2 ? (
                                            <img
                                                src={URL.createObjectURL(image2)}
                                                alt="Uploaded"
                                                className="object-cover w-full h-full rounded-lg"
                                            />
                                        ) : (
                                            <span className="text-gray-400 text-xl font-bold">+</span>
                                        )}
                                    </div>
                                    <input
                                        id="image2"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            setImage2(file);
                                        }}
                                    />

                                    {/* 3rd Upload Button */}
                                    <div
                                        className="flex items-center justify-center w-16 h-11 border border-gray-300 rounded-lg cursor-pointer hover:border-orange-500 hover:bg-orange-50 transition"
                                        onClick={() => document.getElementById('image3').click()}
                                    >
                                        {image3 ? (
                                            <img
                                                src={URL.createObjectURL(image3)}
                                                alt="Uploaded"
                                                className="object-cover w-full h-full rounded-lg"
                                            />
                                        ) : (
                                            <span className="text-gray-400 text-xl font-bold">+</span>
                                        )}
                                    </div>
                                    <input
                                        id="image3"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            setImage3(file);
                                        }}
                                    />

                                    {/* 4th Upload Button */}
                                    <div
                                        className="flex items-center justify-center w-16 h-11 border border-gray-300 rounded-lg cursor-pointer hover:border-orange-500 hover:bg-orange-50 transition"
                                        onClick={() => document.getElementById('image4').click()}
                                    >
                                        {image4 ? (
                                            <img
                                                src={URL.createObjectURL(image4)}
                                                alt="Uploaded"
                                                className="object-cover w-full h-full rounded-lg"
                                            />
                                        ) : (
                                            <span className="text-gray-400 text-xl font-bold">+</span>
                                        )}
                                    </div>
                                    <input
                                        id="image4"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            setImage4(file);
                                        }}
                                    />
                                </>
                                {/* )} */}
                            </div>
                        </div>


                    </div>
                    <span className="flex items-center justify-center">
                        <button
                            className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                            type="submit"
                        >
                            {addStatus === 'pending' ? 'Submitting' : 'Submit'}
                        </button>
                    </span>
                </form >
            </div >
        </>
    );
};
export default AddProduct;
